import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El bolet de tinta presenta un capell en forma de didal que arriben als 2 a 3 cm d’altura per 1,5 a 2,5 cm de diàmetre bassal. La superfície és acanalada, de color crema castanya més fosc al centre. El seu nom fa referència a la polsina que el cobreix quan és jove que recorda el mineral mica i no és més que els restes del vel general. Les làmines són nombroses, blanquinoses, després grisenques i finalment negres per les espores. El peu és blanquinós, cilíndric, de 5 a 10 cm x 3 a 7 mm, de color blanc, llis i molt fràgil. Les espores són negres en massa, el·líptiques, de 7-10 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      